<template>
  <CContainer fluid class="mainTableContainer">
    <CRow alignVertical="center" alignHorizontal="center">
      <CCol lg="12" class="mainTableColumn">
        <CButton color="primary" @click="createCategoryTemplate()"
          >Create</CButton
        >
      </CCol>
      <CCol lg="12" class="mainTableColumn">
        <CDataTable
          :noItemsView="{
            noResults: 'no items found',
            noItems: 'no items',
          }"
          :hover="true"
          :striped="true"
          :items-per-page="20"
          columnFilter
          sorter
          pagination
          :items="getTemplateCategories"
          :clickableRows="true"
          :loading="loading"
          :fields="fields"
          size="sm"
          :tableFilterValue.sync="filter"
          @row-clicked="openTemplateCategoryDetail"
        >
          <template #templates="{ item }">
            <td>
              {{ item.templates.length }}
            </td>
          </template>
          <template #options="{ item }">
            <td>
              <Pencil :size="20" @click.stop="editItem(item)"></Pencil>
              <Delete :size="20" @click.stop="deleteItemShow(item.id)"></Delete>
            </td>
          </template>
        </CDataTable>
      </CCol>
    </CRow>
    <TemplateCategoryModal
      v-if="showModal"
      :item="categoryTemplate"
      @accept="confirmCategoryTemplate"
      :show-modal="showModal"
      :event="event"
      @close="closeModal"
    />
    <DeleteConfirmModal
        :show="showDelete"
        @close="hideDelete"
        @accept="deleteItemConfirm"
    />
  </CContainer>
</template>

<script>
import { CDataTable, CRow, CCol, CContainer, CButton } from "@coreui/vue/src";
import { templateModule } from "@/store/modules/templateModule";
import TemplateCategoryModal from "@/components/modals/TemplateCategoryModal.vue";
import Pencil from "vue-material-design-icons/Pencil.vue";
import DeleteConfirmModal from "@/components/modals/DeleteConfirmModal.vue"
import Delete from "vue-material-design-icons/Delete.vue";
export default {
  name: "TemplateCategories",
  components: {
    TemplateCategoryModal,
    CRow,
    CCol,
    CContainer,
    CDataTable,
    CButton,
    Delete,
    Pencil,
    DeleteConfirmModal
  },
  data() {
    return {
      deleteID:null,
      showDelete:false,
      event: "create",
      showModal: false,
      categoryTemplate: null,
      defaultCategoryTemplate: {
        name: "default name",
      },
      fields: [
        { label: "ID", key: "id", sorter: true, filter: true },
        { label: "Name", key: "name", sorter: true, filter: true },
        {
          label: "Templates count",
          key: "templates",
          sorter: true,
          filter: true,
        },
        { label: "Options", key: "options", sorter: false, filter: false },
      ],
      filter: "",
      loading: false,
    };
  },
  computed: {
    ...templateModule.mapGetters(["getTemplateCategories"]),
  },
  methods: {
    ...templateModule.mapActions([
      "getTemplateCategoriesList",
      "postTemplateCategory",
      "putTemplateCategory",
      "deleteTemplateCategory"
    ]),
    ...templateModule.mapMutations(['setTemplates','setTemplateCategory']),
    deleteItemShow(id){
      this.deleteID = id;
      this.showDelete = true;
    },
    hideDelete(){
      this.showDelete = false;
    },
    async deleteItemConfirm(){
      await this.deleteTemplateCategory(this.deleteID);
      this.showDelete = false;
    },
    editItem(item){
      this.categoryTemplate = JSON.parse(JSON.stringify(item));
      this.event = 'edit';
      this.showModal = true;
    },
    openTemplateCategoryDetail(item) {
      this.setTemplates(item.templates);
      this.setTemplateCategory(item);
      this.$router.push(`/templates/${item.id}`);
    },
    createCategoryTemplate() {
      this.event = "create";
      this.categoryTemplate = JSON.parse(
        JSON.stringify(this.defaultCategoryTemplate)
      );
      this.showModal = true;
    },
    async confirmCategoryTemplate() {
      if (this.event === "create") {
        await this.postTemplateCategory(this.categoryTemplate);
        this.showModal = false;
      } else {
        await this.putTemplateCategory(this.categoryTemplate);
        this.showModal = false;
      }
    },
    closeModal() {
      this.showModal = false;
    },
  },
  async mounted() {
    await this.getTemplateCategoriesList();
  },
};
</script>

<style scoped></style>
